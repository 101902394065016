import 'ol/ol.css';
import './bboxMapWidget.css';
import { DeleteOutlined } from '@ant-design/icons';
import { bbox } from '@turf/bbox';
import { bboxPolygon } from '@turf/bbox-polygon';
import { lineString } from '@turf/helpers';
import { toMercator, toWgs84 } from '@turf/projection';
import { Button, Input, Tooltip } from 'antd';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import Draw, { createBox } from 'ol/interaction/Draw.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM.js';
import VectorSource from 'ol/source/Vector';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import type { toolInput } from '../../../interfaces';
import type { FormInstance } from 'antd';
import type Feature from 'ol/Feature';
import type { Extent } from 'ol/extent';
import type { Geometry, Polygon } from 'ol/geom';

const stringBboxRegex =
  /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;

const olExtentToFeature = (olExtent: Extent) => {
  const [minX, minY, maxX, maxY] = olExtent;

  const line = lineString([
    [minX, minY],
    [maxX, maxY],
  ]);

  const bboxRes = bbox(toWgs84(line));
  const result = bboxPolygon(bboxRes);

  return result;
};

const toolboxExtentToFeature = (extent: number[]) => {
  const [minX, minY, maxX, maxY] = extent;

  const line = lineString([
    [minX, minY],
    [maxX, maxY],
  ]);
  const bboxRes = bbox(line);
  const result = bboxPolygon(bboxRes);

  return result;
};

const isBboxStringValid = (val: string) => {
  if (!stringBboxRegex.test(val)) return false;

  const [minX, minY, maxX, maxY] = val.split(',');
  if (
    Math.abs(parseFloat(minY)) > 90 ||
    Math.abs(parseFloat(maxY)) > 90 ||
    Math.abs(parseFloat(minX)) > 180 ||
    Math.abs(parseFloat(maxX)) > 180
  ) {
    return false;
  }

  return true;
};

export const BboxMapWidget: React.FC<{
  resultCallback: (value: any) => void;
  presetValue?: any;
}> = ({ resultCallback, presetValue }) => {
  //@ts-ignore
  // const [mapObj, setMapObj] = useState<Map>();
  const [sourceObj, setSourceObj] = useState<VectorSource<Feature<Geometry>>>();

  const { t } = useTranslation();

  const handleGetResult = (
    sourceRes: VectorSource<Feature<Geometry>> | undefined,
  ) => {
    if (sourceRes?.isEmpty()) {
      resultCallback(null);
    } else {
      const olExtent = sourceRes?.getFeatures()[0].getGeometry()?.getExtent();
      const res = olExtent ? olExtentToFeature(olExtent) : null;
      resultCallback(res);
    }
  };

  useEffect(() => {
    const presetClone = presetValue
      ? JSON.parse(JSON.stringify(presetValue))
      : null;

    const view = new View({
      center: [0, 4000000],
      zoom: 1,
    });

    const source = presetClone
      ? new VectorSource({
          wrapX: false,
          features: new GeoJSON().readFeatures(toMercator(presetValue)),
        })
      : new VectorSource({
          wrapX: false,
        });

    if (presetClone) {
      const feature = source.getFeatures()[0];
      const polygon = feature.getGeometry();
      view.fit(
        //@ts-ignore
        polygon?.getExtent(),
      );
    }

    setSourceObj(source);

    const pepega = new VectorLayer({
      source: source,
    });

    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        pepega,
      ],
      view: view,
    });

    // setMapObj(map);

    function addInteraction() {
      const draw = new Draw({
        source: source,
        type: 'Circle',
        geometryFunction: createBox(),
      });
      map?.addInteraction(draw);
      draw.on('drawstart', () => source.clear());
      draw.on('drawend', () => {
        setTimeout(() => {
          handleGetResult(source);
        }, 0); // this is dirty hack, dunno how to do it other way for now

        // try useCallback with useState for source instead of setTimeout
      });
    }

    addInteraction();

    return () => map.setTarget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetValue]);

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <div
        id="map"
        style={{
          width: '100%',
          height: '400px',
          position: 'relative',
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 100,
            top: '8px',
            right: '8px',
          }}
        >
          <Tooltip title={t('customUI.bboxClearTooltip')} mouseEnterDelay={0.6}>
            <Button
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                sourceObj?.clear();
                resultCallback(null);
              }}
            ></Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const BboxMapWidgetInput: React.FC<{
  input: toolInput;
  form: FormInstance<any>;
  onValueChange?: (value: string) => void;
}> = ({ input, form, onValueChange }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const [valueCounter, setValueCounter] = useState(0);
  // eslint-disable-next-line prettier/prettier
  const [preservedValue, setPreservedValue] = useState<Feature<Polygon> | null>(null);

  const [textInputValue, setTextInputValue] = useState('');
  const [textInputValueValid, setTextInputValueValid] = useState(true);

  const realCallback = (val: any) => {
    const normalizedBbox = val
      ? val.bbox.map((coord: number) => `${coord}`).join(',')
      : undefined;

    setTextInputValue(normalizedBbox);

    form.setFieldValue(input.name, normalizedBbox);
    if (onValueChange) {
      onValueChange(input.name);
    }
    setTextInputValueValid(true);
  };

  useEffect(() => {
    setValueCounter(valueCounter + 1);
    if (
      valueCounter === 1 &&
      form.getFieldValue(input.name) &&
      searchParams.has('bbox')
    ) {
      const presetFormValue = form
        .getFieldValue(input.name)
        ?.split(',')
        .map(parseFloat);

      const presetBboxFeature = presetFormValue
        ? toolboxExtentToFeature(presetFormValue)
        : null;

      //@ts-ignore
      setPreservedValue(presetBboxFeature);
      setTextInputValue(presetBboxFeature?.bbox?.join(',') as string);
    }
  }, [form.getFieldValue(input.name)]); // doesn't work other way

  return (
    <>
      <Input
        style={{ marginBottom: '4px' }}
        value={textInputValue}
        allowClear
        status={textInputValueValid ? undefined : 'error'}
        onChange={(e) => {
          setTextInputValueValid(isBboxStringValid(e.target.value));
          setTextInputValue(e.target.value);

          if (isBboxStringValid(e.target.value)) {
            const presetFormValue = e.target.value?.split(',').map(parseFloat);
            const presetBboxFeature = presetFormValue
              ? toolboxExtentToFeature(presetFormValue)
              : null;
            //@ts-ignore
            setPreservedValue(presetBboxFeature);

            form.setFieldValue(input.name, e.target.value);
          }
        }}
        onClear={() => {
          form.setFieldValue(input.name, undefined);
          setPreservedValue(null);
          setTextInputValue('');
          setTextInputValueValid(true);
          // form.validateFields();
        }}
      />
      {textInputValueValid || (
        <span style={{ color: 'red' }}>{t('customUI.bboxWrongString')}</span>
      )}
      <BboxMapWidget
        resultCallback={realCallback}
        presetValue={preservedValue}
      />
    </>
  );
};
