import { SampleCustomUI } from './SampleCustomUI.tsx';
import { BboxMapWidgetInput } from './widgets/BboxMapWidget.tsx';

export const getCustomUI = (id?: string) => {
  switch (id) {
    case 'test':
      return null;

    default:
      return SampleCustomUI;
  }
};

export const getWidget = (widgetName: string) => {
  switch (widgetName) {
    case 'test':
      return BboxMapWidgetInput;

    default:
      return BboxMapWidgetInput;
  }
};
