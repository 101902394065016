import { BboxMapWidget } from './widgets/BboxMapWidget.tsx';

const dummyCallback = (result: any) => {
  console.log('We got it!', result);
};

export const SampleCustomUI: React.FC = () => {
  return (
    <div style={{ padding: '12px 0px 0px 0px' }}>
      <BboxMapWidget resultCallback={dummyCallback} />
    </div>
  );
};
